<template>
  <SvgIcon name="report-arrow" :width="props.width" :height="props.height" />
</template>
<script lang="ts" setup>
const props = defineProps({
  width: {
    type: Number,
    default: 14
  },
  height: {
    type: Number,
    default: 14
  }
})
</script>
<style lang="scss" scoped></style>
