<template>
  <el-drawer
    v-model="isShowViewDrawer"
    :show-close="false"
    modal-class="elv-report-source-overlay"
    class="elv-report-source-drawer"
    :before-close="onCheckDrawerStatus"
    :size="450"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-report-source-drawer-header__title">
        {{ props.drawerTitle === 'Account' ? t('common.account') : t('common.contact') }}
      </h4>
      <SvgIcon
        name="close"
        width="24"
        height="24"
        class="elv-report-source-drawer-header__close"
        @click="onCheckDrawerStatus"
      ></SvgIcon>
    </template>

    <template v-if="!props.isBatch && props.drawerTitle === 'Account'">
      <div class="elv-report-source-drawer-content">
        <div class="elv-report-source-drawer-content-currency">
          <img
            v-if="currencyData?.logo"
            :src="currencyData?.logo"
            :onerror="useDefaultImage"
            :alt="currencyData?.name"
          />
          <img
            v-else-if="
              props.drawerData?.platform?.type !== 'MARKET' &&
              currencyData?.name !== 'API' &&
              currencyData?.name !== 'Bank'
            "
            :onerror="useDefaultImage"
            src="@/assets/img/default-logo.png"
            alt="logo"
          />
          <span v-if="currencyData?.name !== 'Bank'" class="elv-report-source-drawer-currency__name">{{
            currencyData?.name
          }}</span>
          <el-select
            v-model="fromData.role"
            :suffix-icon="SuffixIcon"
            popper-class="elv-report-source-drawer-purpose-popper"
            @change="onEditAccount"
          >
            <template #prefix>
              {{
                capitalize(transformI18n(find(reportTransactionType, { value: fromData.role.toUpperCase() })?.label))
              }}
            </template>
            <el-option
              v-for="item in reportTransactionType"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
          <!-- <span class="elv-report-source-drawer-currency-type">{{ currentPurpose }}</span> -->
        </div>
        <div class="elv-report-source-drawer-content-name">
          <span
            ref="nameInputRef"
            contenteditable="true"
            class="elv-report-drawer-input"
            placeholder="Unnamed"
            @keydown="onKeyDown"
            @keyup.enter="onKeyUpEnter"
            @click.prevent="onClickPrevented"
            @blur="onBlurInput"
          ></span>
          <SvgIcon name="sources-edit" width="16" height="16" @click="onEditName" />
        </div>

        <div v-if="paymentIdentifier" class="elv-report-source-drawer-content-paymentIdentifier">
          <p :data-clipboard-text="paymentIdentifier">{{ paymentIdentifier }}</p>
          <SvgIcon
            v-if="props.drawerData?.platform?.type !== 'MARKET'"
            name="sources-copy"
            width="12"
            height="12"
            @click="onClickedCopy(paymentIdentifier)"
          />
        </div>
      </div>

      <div class="elv-report-source-drawer-memo">
        <p>{{ t('report.memo') }}</p>
        <el-input v-model="fromData.memo" :autosize="{ minRows: 5 }" type="textarea" @blur="onBlurMemo" />
      </div>
    </template>

    <template v-if="!props.isBatch && props.drawerTitle === 'Contact'">
      <div class="elv-report-source-drawer-contact-info">
        <img v-if="currencyData?.logo" :src="currencyData?.logo" :onerror="useDefaultImage" :alt="currencyData?.name" />
        <SvgIcon
          v-else-if="
            (props.drawerData?.chainId === null &&
              props.drawerData?.exchangeId === null &&
              currencyData?.name !== 'Bank') ||
            (props.drawerData?.platform?.chainId === null &&
              props.drawerData?.platform?.exchangeId === null &&
              props.drawerData?.platform?.type !== 'MARKET')
          "
          class="elv-report-source-drawer-contact-info__logo"
          name="sources-csv"
          width="20"
          height="20"
        />
        <img
          v-else-if="
            props.drawerData?.platform?.type !== 'MARKET' &&
            currencyData?.name !== 'API' &&
            currencyData?.name !== 'Bank'
          "
          :onerror="useDefaultImage"
          src="@/assets/img/default-logo.png"
          alt="logo"
        />
        <div>
          <span v-if="props.drawerData?.platform?.type !== 'MARKET' && currencyData?.name !== 'Bank'">{{
            currencyData?.name ?? '-'
          }}</span>
          <p :data-clipboard-text="paymentIdentifier">
            {{ paymentIdentifier
            }}<SvgIcon name="sources-copy" width="12" height="12" @click="onClickedCopy(paymentIdentifier)" />
          </p>
        </div>
      </div>
      <el-form
        ref="contactFormRef"
        class="elv-report-source-contact-form"
        :model="fromData"
        label-position="top"
        :rules="rules"
      >
        <el-form-item :label="t('report.contactType')" prop="role">
          <el-select
            v-model="fromData.role"
            class="elv-report-source-drawer-contact-select"
            popper-class="elv-report-source-drawer-contact-popper"
          >
            <template v-if="fromData.role" #prefix>
              <SvgIcon :name="`report-contact-${fromData.role}`" width="20" height="20" />
            </template>
            <el-option
              v-for="item in reportContactType"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            >
              <SvgIcon :name="`${item.icon}`" width="20" height="20" />
              <span>{{ transformI18n(item.label) }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Name" prop="name">
          <el-input v-model="fromData.name" placeholder="" />
        </el-form-item>
        <el-form-item :label="t('report.memo')" prop="memo">
          <el-input v-model="fromData.memo" :autosize="{ minRows: 5 }" type="textarea" />
        </el-form-item>
      </el-form>

      <el-button
        class="elv-report-source-drawer-content__save"
        :disabled="!fromData.role || !fromData.name"
        :loading="saveLoading"
        @click="onSave"
      >
        {{ t('button.save') }}
      </el-button>
    </template>

    <template v-else-if="props.drawerTitle === 'Contact'">
      <div class="elv-report-source-drawer-content__total">
        <span>{{ t('common.selected') }}</span>
        <p>{{ t('report.totalContacts', { total: props.totalContacts }) }}</p>
      </div>
      <el-form
        ref="contactFormRef"
        class="elv-report-source-contact-form"
        :model="fromData"
        label-position="top"
        :rules="rules"
      >
        <el-form-item :label="t('report.contactType')" prop="role">
          <el-select
            v-model="fromData.role"
            class="elv-report-source-drawer-contact-select"
            popper-class="elv-report-source-drawer-contact-popper"
          >
            <template v-if="fromData.role" #prefix>
              <SvgIcon :name="`report-contact-${fromData.role}`" width="20" height="20" />
            </template>
            <el-option
              v-for="item in reportContactType"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            >
              <SvgIcon :name="`${item.icon}`" width="20" height="20" />
              <span>{{ transformI18n(item.label) }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!reportStore.batchEditSelected[0]?.name" label="Name" prop="name">
          <el-input v-model="fromData.name" placeholder="" :disabled="!reportStore.batchEditSelected[0]?.name" />
        </el-form-item>
        <el-form-item :label="t('report.memo')" prop="memo">
          <el-input v-model="fromData.memo" :autosize="{ minRows: 5 }" type="textarea" />
        </el-form-item>
      </el-form>

      <el-button class="elv-report-source-drawer-content__save" :loading="saveLoading" @click="onSave">
        {{ t('button.save') }}
      </el-button>
    </template>
    <template #footer>
      <div
        v-if="
          props.drawerTitle === 'Contact' &&
          ((!props.isBatch && props.drawerData.name) ||
            (props.isBatch && reportStore.batchEditSelected[0]?.name) ||
            (props.tableType === 'transaction' && props.drawerData.name))
        "
        v-loading.fullscreen.lock="deleteLoading"
        class="elv-report-source-drawer-footer__delete"
        @click="onDelete"
      >
        <SvgIcon name="sources-delete" width="16" height="16" />
        {{ props.isBatch ? t('button.deleteContacts') : t('button.deleteContact') }}
      </div>
      <div
        v-if="
          props.drawerTitle === 'Account' &&
          ((!props.isBatch && props.drawerData.name) ||
            (props.isBatch && reportStore.batchEditSelected[0]?.name) ||
            (props.tableType === 'transaction' && props.drawerData.name))
        "
        v-loading.fullscreen.lock="deleteLoading"
        class="elv-report-source-drawer-footer__delete"
        @click="onDeleteAccount"
      >
        <SvgIcon name="sources-delete" width="16" height="16" />
        {{ props.isBatch ? t('button.deleteAccounts') : t('button.deleteAccount') }}
      </div>
    </template>
  </el-drawer>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import useClipboard from 'vue-clipboard3'
import ReportsApi from '@/api/ReportsApi'
import { transformI18n } from '@/i18n/index'
import SuffixIcon from '@/components/SuffixIcon.vue'
import defaultImg from '@/assets/img/default-logo.png'
// eslint-disable-next-line no-unused-vars
import type { FormInstance, FormRules } from 'element-plus'
import { useReportStore } from '@/stores/modules/reports/index'
import { reportContactType, reportTransactionType } from '@/config/reports'
import { map, max, find, uniq, toUpper, filter, isEmpty, capitalize } from 'lodash-es'

const props = defineProps({
  tableType: {
    type: String,
    default: 'account' // account || contact || transaction
  },
  drawerTitle: {
    type: String,
    default: 'Contact' // Account || Contact
  },
  drawerData: {
    type: Object,
    required: true
  },
  isBatch: {
    type: Boolean,
    default: false
  },
  totalContacts: {
    type: Number,
    default: 2
  }
})

const { t } = useI18n()
const route = useRoute()
const { toClipboard } = useClipboard()
const reportStore = useReportStore()
const fromData = reactive({
  name: '',
  role: '',
  memo: ''
})
const nameInputRef = ref()
const contactFormRef = ref()
const paymentIdentifier = ref('')
const contactNameStartIndex = ref(0)
const isShowViewDrawer = ref(false)
const saveLoading = ref(false)
const deleteLoading = ref(false)
const emit = defineEmits(['resetList', 'onUpdateAccountOrContact'])

const entityId = computed(() => {
  return Number(route.params?.entityId)
})

const currentEntityPermission = computed(() => {
  return reportStore.entityPermission()
})

// const currentPurpose = computed(() => {
//   let purpose = ''
//   if (props.drawerData?.purpose) purpose = capitalize(props.drawerData?.purpose)
//   if (props.drawerData?.source?.purpose) purpose = capitalize(props.drawerData?.source?.purpose)
//   return purpose
// })

const currencyData = computed(() => {
  if (props.drawerData?.source?.sourceName === 'everPay' && props.drawerData?.source?.type === 'API') {
    return {
      logo: 'https://static.elven.com/project/everpay.png',
      name: 'everPay'
    }
  }
  if (props.drawerData?.source?.type === 'CSV' && props.drawerData?.source?.csvFile?.templateType === 'BANK') {
    return {
      logo:
        props.drawerTitle === 'Account'
          ? props.drawerData?.platform?.logo ?? ''
          : 'https://static.elven.com/bank/Other%20Bank.png',
      name: props.drawerTitle === 'Account' ? props.drawerData?.platform?.name || 'Bank' : t('common.otherBank')
    }
  }
  if (
    props.drawerData?.platform?.type === 'MARKET' ||
    props.drawerData?.source?.type === 'MARKET' ||
    (props.drawerData?.source?.type === 'CSV' &&
      props.drawerData?.source?.csvFile?.templateType === 'GENERAL' &&
      !props.drawerData?.chain?.logo &&
      !props.drawerData?.exchange?.logo &&
      !props.drawerData?.platform?.logo &&
      !props.drawerData?.source?.chain?.logo &&
      !props.drawerData?.source?.exchange?.logo)
  ) {
    return {
      logo: '',
      name: 'API'
    }
  }
  return {
    logo:
      props.drawerData?.chain?.logo ||
      props.drawerData?.exchange?.logo ||
      props.drawerData?.platform?.logo ||
      props.drawerData?.source?.chain?.logo ||
      props.drawerData?.source?.exchange?.logo,
    name:
      props.drawerData?.chain?.name ||
      props.drawerData?.exchange?.name ||
      props.drawerData?.platform?.name ||
      props.drawerData?.source?.chain?.name ||
      props.drawerData?.source?.exchange?.name
  }
})

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: 'Name is required'
  },
  role: {
    required: true,
    trigger: 'change',
    message: 'Contact Type is required'
  }
})

const onClickedCopy = async (msg: string) => {
  try {
    await toClipboard(msg)
    ElMessage.success(t('common.copied'))
  } catch (e) {
    console.error(e)
  }
}

const onDelete = async () => {
  try {
    if (!currentEntityPermission.value?.contact?.delete) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    let params = {}
    deleteLoading.value = true
    if (props.isBatch) {
      const ids = map(reportStore.batchEditSelected, (item: any) => item.contactId)
      params = { contactIds: ids }
    } else {
      params = { contactIds: [props.drawerData.contactId] }
    }
    await ReportsApi.deleteContact(entityId.value, params)
    ElMessage.success(t('message.deleteSuccess'))
    emitter.emit('resetList')
    if (props.isBatch) {
      reportStore.batchEditSelected = []
    }
    if (props.tableType === 'transaction') {
      reportStore.fetchContactDataList(entityId.value)
    }
    isShowViewDrawer.value = false
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
  } finally {
    deleteLoading.value = false
  }
}

const onKeyUpEnter = () => {
  nameInputRef.value?.blur()
}

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

const onKeyDown = (e: any) => {
  if (e.keyCode === 13) {
    // 阻止换行
    e.cancelBubble = true
    e.preventDefault()
    e.stopPropagation()

    // 取值
    fromData.name = e.target.innerText
  }
}

const onEditName = () => {
  if (window.getSelection) {
    nameInputRef.value?.focus()
    const range: any = window.getSelection() // 创建range
    range.selectAllChildren(nameInputRef.value) // range 选择nameInputRef下所有子内容
    range.collapseToEnd() // 光标移至最后
  } else if (document.getSelection) {
    // ie10 9 8 7 6 5
    const range: any = document.getSelection() // 创建选择对象
    range.moveToElementText(nameInputRef.value) // range定位到nameInputRef
    range.collapse(false) // 光标移至最后
    range.select()
  }
}

const onClickPrevented = (e: any) => {
  // eslint-disable-next-line no-param-reassign
  e = e || window.event
  if (e.preventDefault) {
    e.preventDefault() // 非ie和ie8以上浏览器
  } else {
    e.returnValue = false // ie8以下浏览器
  }

  return false // 只写这个，也兼容了所有的浏览器。
}

const onEditContact = async () => {
  try {
    if (!currentEntityPermission.value?.contact?.update) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    const params = {
      name: fromData.name,
      role: fromData.role.toLocaleUpperCase(),
      memo: fromData.memo
    }
    await ReportsApi.editContact(entityId.value, props.drawerData?.contactId, params)
    ElMessage.success(t('message.editSuccess'))
    reportStore.loading = true
    emit('resetList')
    if (props.tableType === 'transaction') {
      reportStore.fetchContactDataList(entityId.value)
    }
    isShowViewDrawer.value = false
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  }
}

const onAddContact = async () => {
  try {
    if (!currentEntityPermission.value?.contact?.create) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (fromData.name && fromData.role !== 'Unset') {
      const params: any = {
        name: fromData.name,
        role: fromData.role.toLocaleUpperCase(),
        memo: fromData.memo ? fromData.memo : '',
        value: paymentIdentifier.value
      }
      if (
        props.drawerData?.source?.type === 'CSV' ||
        (props.drawerData?.platformId && props.drawerData?.source?.type !== 'CSV')
      ) {
        params.platformId = props.drawerData?.platformId
      }
      const response = await ReportsApi.addContact(entityId.value, params)
      emit('onUpdateAccountOrContact', { ...props.drawerData, ...response.data })
      ElMessage.success(t('message.editSuccess'))
      reportStore.loading = true
      emit('resetList')
      if (props.tableType === 'transaction' || route.name === 'reports-contacts-unnamed') {
        reportStore.fetchContactDataList(entityId.value)
      }
      contactFormRef.value.resetFields()
      isShowViewDrawer.value = false
    }
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
  } finally {
    saveLoading.value = false
  }
}

const onEditAccount = async () => {
  try {
    if (!currentEntityPermission.value?.entityAccount?.update) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    const params = {
      name: fromData.name,
      memo: fromData.memo,
      purpose: toUpper(fromData.role)
    }
    await ReportsApi.editProjectAccount(entityId.value, props.drawerData?.entityAccountId, params)
    ElMessage.success(t('message.editSuccess'))
    reportStore.loading = true
    emit('resetList')
    isShowViewDrawer.value = false
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error?.message)
  } finally {
    saveLoading.value = false
  }
}

const onDeleteAccount = async () => {
  try {
    // if (!currentEntityPermission.value?.entityAccount?.delete) {
    //   ElMessage.warning(t('message.noPermission'))
    //   return
    // }
    let params = {}
    deleteLoading.value = true
    params = { entityAccountIds: [props.drawerData.entityAccountId] }
    await ReportsApi.deleteProjectAccount(entityId.value, params)
    isShowViewDrawer.value = false
    ElMessage.success(t('message.deleteSuccess'))
    reportStore.loading = true
    emit('resetList')
    if (props.tableType === 'transaction') {
      reportStore.fetchContactDataList(entityId.value)
    }
  } catch (error: any) {
    ElMessage.error(error?.message)
    console.log(error)
  } finally {
    deleteLoading.value = false
  }
}

// 保存
const onSave = async () => {
  try {
    if (!currentEntityPermission.value?.contact?.update) {
      ElMessage.warning(t('message.noPermission'))
      return
    }
    if (!saveLoading.value && props.isBatch && fromData.role && reportStore.batchEditSelected[0].name) {
      saveLoading.value = true
      const ids = map(reportStore.batchEditSelected, (item: any) => item.contactId)
      const params = { contactIds: [...ids], role: fromData.role.toLocaleUpperCase() }
      await ReportsApi.editBatchContact(entityId.value, params)
      saveLoading.value = false
      ElMessage.success(t('message.editSuccess'))
      reportStore.loading = true
      emitter.emit('resetList')
      reportStore.batchEditSelected = []
      if (props.tableType === 'transaction') {
        reportStore.fetchContactDataList(entityId.value)
      }
      contactFormRef.value.resetFields()
      isShowViewDrawer.value = false
    } else if (!saveLoading.value && props.isBatch && fromData.role && !reportStore.batchEditSelected[0].name) {
      if (!currentEntityPermission.value?.contact?.create) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      saveLoading.value = true
      const contacts = map(reportStore.batchEditSelected, (item: any) => {
        // eslint-disable-next-line no-plusplus
        contactNameStartIndex.value++
        const data: any = {
          name: `${fromData.role}${contactNameStartIndex.value}`,
          role: fromData.role.toLocaleUpperCase(),
          memo: fromData.memo,
          value: item.address
        }
        if (item?.platform?.platformId) {
          data.platformId = item?.platform?.platformId
        }
        return data
      })
      await ReportsApi.addBatchContact(entityId.value, { contacts })
      saveLoading.value = false
      reportStore.batchEditSelected = []
      ElMessage.success(t('message.editSuccess'))
      reportStore.loading = true
      emitter.emit('resetList')
      reportStore.fetchContactDataList(entityId.value)
      contactFormRef.value.resetFields()
      isShowViewDrawer.value = false
    } else if (!saveLoading.value) {
      await contactFormRef.value.validate(async (valid: any) => {
        if (valid) {
          saveLoading.value = true
          if (isEmpty(props.drawerData) || !props.drawerData?.contactId) {
            await onAddContact()
          } else {
            await onEditContact()
          }
        }
      })
    }
  } catch (error: any) {
    ElMessage.error(error?.message)
    saveLoading.value = false
    console.log(error)
  }
}

const onBlurInput = async (e: any) => {
  fromData.name = e.target?.innerText
  if (fromData.role === 'Unset') {
    ElMessage.warning(t('message.pleaseSelectContactType'))
    return
  }
  if (fromData.role !== 'Unset' && fromData.name !== props.drawerData?.name)
    switch (props.drawerTitle) {
      case 'Contact':
        if (isEmpty(props.drawerData) || !props.drawerData?.contactId) {
          await onAddContact()
        } else {
          await onEditContact()
        }
        break
      default:
        await onEditAccount()
        break
    }
}

const onBlurMemo = async () => {
  if (fromData.role && fromData.name && fromData.memo !== props.drawerData?.memo) {
    switch (props.drawerTitle) {
      case 'Contact':
        if (isEmpty(props.drawerData) || !props.drawerData?.contactId) {
          await onAddContact()
        } else {
          await onEditContact()
        }
        break
      default:
        await onEditAccount()
        break
    }
  }
}

const onCheckDrawerStatus = () => {
  isShowViewDrawer.value = !isShowViewDrawer.value
  if (!isShowViewDrawer.value) {
    fromData.name = ''
  }
}

defineExpose({ onCheckDrawerStatus })

watch(
  () => fromData.role,
  () => {
    if (props.isBatch && fromData.role && !reportStore.batchEditSelected[0]?.name) {
      const nameList = map(find(reportStore.contactList, { type: fromData.role.toUpperCase() })?.list, 'name')
      const nameListFilter = filter(nameList, (item: any) => item.startsWith(fromData.role))
      const nameListFilterMap = map(nameListFilter, (item: any) => Number(item.slice(fromData.role.length)))
      const nameListFilterMapMax = max(nameListFilterMap)
      contactNameStartIndex.value = nameListFilterMapMax ?? 0
      if (reportStore.batchEditSelected.length > 2) {
        fromData.name = `${fromData.role}${contactNameStartIndex.value + 1}、${fromData.role}${
          contactNameStartIndex.value + 2
        }...`
      } else {
        fromData.name =
          reportStore.batchEditSelected.length === 2
            ? `${fromData.role}${contactNameStartIndex.value + 1}、${fromData.role}${contactNameStartIndex.value + 2}`
            : `${fromData.role}${contactNameStartIndex.value + 1}`
      }
    }
  }
)

watch([() => props.drawerData, () => isShowViewDrawer.value], () => {
  if (isShowViewDrawer.value) {
    nextTick(() => {
      if (props.isBatch) {
        const isSameRole = uniq(map(reportStore.batchEditSelected, 'role')).length === 1
        fromData.role = isSameRole ? capitalize(reportStore.batchEditSelected[0]?.role) : 'Unset'
      } else {
        fromData.name = props.drawerData?.name
        fromData.memo = props.drawerData?.memo
        if (props.drawerTitle === 'Account') {
          // eslint-disable-next-line no-nested-ternary
          fromData.role = props.drawerData?.purpose
            ? capitalize(props.drawerData?.purpose)
            : props.drawerData?.source?.purpose
            ? capitalize(props.drawerData?.source?.purpose)
            : 'Unset'
        } else {
          fromData.role = props.drawerData?.role ? capitalize(props.drawerData?.role) : ''
        }
        if (nameInputRef.value) nameInputRef.value.innerText = fromData.name ? fromData.name : ''
        // eslint-disable-next-line no-nested-ternary
        paymentIdentifier.value = props.drawerData?.paymentIdentifier
          ? props.drawerData?.paymentIdentifier
          : props.drawerData?.address
          ? props.drawerData?.address
          : props.drawerData?.value ?? props.drawerData?.cardNumber
      }
    })
  }
})
</script>

<style lang="scss" scoped>
.elv-report-source-drawer {
  .elv-report-source-drawer-header__title {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e0f11;
    margin: 0;
  }

  .elv-report-source-drawer-header__close {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
.elv-report-source-drawer {
  .el-drawer__header {
    padding: 26px 0px 18px;
    margin: 0 24px;
    border-bottom: 1px solid #edf0f3;
    height: 64px;
    box-sizing: border-box;
  }

  .el-drawer__body {
    padding: 24px;
    margin-bottom: 16px;
  }

  .el-drawer__footer {
    text-align: left;
    padding: 0px 24px 50px;
  }

  .elv-report-source-contact-form {
    .el-form-item {
      margin-bottom: 16px;

      .el-input {
        width: 100%;
        height: 44px;
        border-radius: 4px;

        &.is-disabled {
          .el-input__wrapper {
            background: #f9fafb;
          }
        }
      }

      .el-input__inner {
        font-family: 'Plus Jakarta Sans';
        font-weight: 400;
        font-size: 14px;
        color: #0e0f11;
      }

      .el-input__wrapper {
        border-radius: 4px;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        padding: 1px 12px;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      .el-input:not(.is-disabled) .el-input__wrapper {
        &:hover {
          border: 1px solid #7596eb !important;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
        }

        &.is_focus {
          border: 1px solid #7596eb !important;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
        }
      }

      .el-textarea:not(.is-disabled) .el-textarea__inner {
        &:hover {
          border: 1px solid #7596eb !important;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
        }

        &:focus {
          border: 1px solid #7596eb !important;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
        }
      }

      &:last-of-type {
        margin-bottom: 16px;
      }

      .el-form-item__content {
        .elv-report-source-drawer-contact-select {
          margin-bottom: 0px;
        }
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
        color: #636b75;

        &::before {
          display: none;
        }
      }
    }
  }

  .elv-report-source-drawer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    margin-bottom: 10px;
  }

  .elv-report-source-drawer-content-currency {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      display: block;
    }

    .elv-report-source-drawer-currency__name {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;
      margin-left: 6px;
      margin-right: 8px;
      padding-right: 8px;
      border-right: 1px solid #edf0f3;
    }

    .elv-report-source-drawer-currency-type {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;
    }

    .el-input {
      border-radius: 0px;
      height: fit-content;
    }

    .el-input .el-input__wrapper {
      padding: 0;
      box-shadow: none !important;
      border: 0px;

      .el-input__inner {
        display: none;
      }

      .el-input__prefix {
        height: 16px;
        width: auto;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #838d95;

        .el-input__prefix-inner > :last-child {
          margin-right: 0;
        }
      }

      .el-input__suffix-inner {
        width: 16px;
        height: 16px;

        i,
        svg {
          width: 16px;
          height: 16px;
        }

        i {
          margin-left: 0px;
        }
      }
    }
  }

  .elv-report-source-drawer-content-name {
    height: 30px;
    display: flex;
    align-items: baseline;
    margin-top: 8px;

    &:hover {
      .elv-report-drawer-input {
        border-bottom-color: #aaafb6;
        cursor: pointer;
      }
    }

    .elv-report-drawer-input {
      height: 30px;
      white-space: nowrap;
      display: inline-block;
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #1e2024;
      border-bottom: 1px solid transparent;
      transition: border-bottom-color 0.3s ease-in-out;

      &:empty::before {
        height: 30px;
        content: attr(placeholder);
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #dde1e6;
      }
    }

    [contenteditable='true'] {
      /* 光标的颜色 */
      caret-color: #1e2024;

      &:focus {
        outline: unset;
      }
    }

    svg {
      cursor: pointer;
      margin-left: 6px;
      fill: #dde1e6;
    }
  }

  .elv-report-source-drawer-content-paymentIdentifier {
    display: flex;
    align-items: center;
    margin-top: 8px;

    p {
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      color: #838d95;
      margin-right: 4px;
    }

    svg {
      cursor: pointer;
    }
  }

  .elv-report-source-drawer-memo {
    border-top: 1px solid #edf0f3;
    padding-top: 16px;
    margin-bottom: 16px;

    p {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-bottom: 8px;
    }

    .el-textarea__inner {
      padding: 8px 16px;
      width: 402px;
      min-height: 120px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #0e0f11;

      &:hover,
      &:focus {
        border: 1px solid #5e85eb;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .elv-report-source-drawer-contact-info {
    box-sizing: border-box;
    align-items: stretch;
    display: flex;
    padding: 16px 12px;
    width: 100%;
    height: 88px;
    background: #f9fafb;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    margin-bottom: 16px;

    img,
    .elv-report-source-drawer-contact-info__logo {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }

    > div {
      display: flex;
      flex-direction: column;

      span {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        // line-height: 28px;
        display: flex;
        align-items: center;
        color: #0e0f11;
        height: 20px;
      }

      p {
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 28px;
        display: flex;
        align-items: center;
        height: 28px;
        margin-top: 4px;
        color: #1e2024;

        svg {
          margin-left: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .elv-report-source-drawer-content__total {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 16px;

    span {
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #838d95;
    }

    p {
      font-family: 'Barlow';
      font-weight: 600;
      font-size: 22px;
      line-height: 18px;
      color: #1e2024;
    }
  }

  .elv-report-source-drawer-contact-memo {
    margin-bottom: 16px;

    p {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-bottom: 8px;
    }

    .el-textarea__inner {
      padding: 8px 16px;
      width: 402px;
      min-height: 120px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #0e0f11;

      &:hover,
      &:focus {
        border: 1px solid #5e85eb;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      }
    }
  }

  .elv-report-source-drawer-content__save {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;
    height: 36px;
    background: #1753eb;
    box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
    border-radius: 3px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
    box-sizing: border-box;
    cursor: pointer;
    border: 0px;
    width: 100%;

    &.is-disabled {
      background: #edf0f3;
      box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #a3a3a3;
      color: #838d95;
    }

    &:not(.is-disabled):active {
      background: #1343bf;
      box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
    }

    &:not(.is-disabled):hover {
      background: #2f63eb;
      box-shadow: 0px 2px 3px rgba(44, 49, 64, 0.1), 0px 0px 1px #0048ff;
    }
  }

  .elv-report-source-drawer-footer__delete {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: fit-content;
    height: 32px;
    border: 1px solid #dde1e6;
    border-radius: 2px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #1e2024;
    margin: 0 auto;
    cursor: pointer;

    svg {
      margin-right: 8px;
      fill: #1e2024;
    }

    &:hover {
      color: #1343bf;
      border-color: #7596eb;

      svg {
        fill: #1343bf;
      }
    }
  }
}

.elv-report-source-drawer-purpose-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 4px 0;
  }

  .el-select-dropdown__item {
    height: 32px;
    width: 124px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #394048;

    &.hover {
      background: #f9fafb;
    }
  }
}

.elv-report-source-drawer-contact-title {
  font-family: 'Plus Jakarta Sans';
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #636b75;
  margin-bottom: 8px;
}

.elv-report-source-drawer-contact-select {
  width: 100%;
  margin-bottom: 16px;

  .el-input__wrapper {
    padding: 12px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid #dde1e6;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    &:hover {
      border: 1px solid #5e85eb;
      box-shadow: none;
    }

    .el-input__prefix-inner > :last-child {
      margin-right: 10px;
    }
  }
}

.elv-report-source-drawer-contact-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    width: 100%;
    padding: 10px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    span {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #0e0f11;
      margin-left: 10px;
    }

    &.hover {
      background: #f9fafb;
    }
  }
}
</style>
